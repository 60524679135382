@import 'colors';
$color-box-shadow: rgba(0, 0, 0, 0.12);

@mixin btn-appender {
  line-height: 1.3333333 !important;
  border-radius: 50px !important;
  margin-left: 10px !important;
  font-weight: 500;

  em {
    font-size: 16px;
    font-weight: 700;
  }
}

// add this class in the btn-link buttons you use as real buttons
.btn-box {
  border-radius: 50px;
  box-shadow: 0 2px 5px 0 $color-box-shadow, 0 2px 10px 0 $color-box-shadow;
}

.btn:active,
.btn:focus,
.btn.active {
  outline: 0 !important;
  color: $color-white;
  box-shadow: 0 0 1px 1px $color-black;
}

.btn:focus {
  box-shadow: 1px 1px 5px $color-grey !important;
}

// Primary

.btn-primary {
  @include btn-appender;
  background: $btn-primary !important;
  border-color: $btn-primary !important;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: $btn-primary-focus !important;
  border-color: $btn-primary-focus !important;
}

.btn-primary.active {
  background-color: $btn-primary !important;
}

// Secondary
.btn-secondary {
  @include btn-appender;
  background-color: $btn-secondary !important;
  border: 2px solid $btn-secondary-border-text !important;
  color: $btn-secondary-border-text;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: $btn-secondary-focus !important;
  color: $btn-secondary-border-text;
}

.btn-secondary.active {
  background-color: $btn-secondary !important;
}

.btn-secondary.active:hover {
  color: $color-white;
}

.btn-secondary.active:focus {
  color: $color-white;
}

// Default
.btn-default {
  @include btn-appender;
  background: $btn-default !important;
  border-color: $btn-default !important;
}

.btn-default:hover,
.btn-default:focus {
  background-color: $btn-default-focus !important;
}

.btn-default.active {
  background-color: $btn-default-active !important;
}

// Success
.btn-success {
  @include btn-appender;
  background: $btn-success !important;
  border-color: $btn-success !important;
}

.btn-success:hover,
.btn-success:focus {
  background-color: $btn-success-focus !important;
}

.btn-success.active {
  background-color: $btn-success-active !important;
}

// Info
.btn-info {
  @include btn-appender;
  background: $btn-info !important;
  border-color: $btn-info !important;
}

.btn-info:hover,
.btn-info:focus {
  background-color: $btn-info-focus !important;
}

.btn-info.active {
  background-color: $btn-info-active !important;
}

// Warning
.btn-warning {
  @include btn-appender;
  background: $btn-warning !important;
  border-color: $btn-warning !important;
}

.btn-warning:hover,
.btn-warning:focus {
  background-color: $btn-warning-focus !important;
}

.btn-warning.active {
  background-color: $btn-warning-active !important;
}

// Danger
.btn-danger {
  @include btn-appender;
  background: $btn-danger !important;
  border-color: $btn-danger !important;
}

.btn-danger:hover,
.btn-danger:focus {
  background-color: $btn-danger-focus !important;
}

.btn-danger.active {
  background-color: $btn-danger-active !important;
}

// Link
.btn-link {
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 0 !important;
  color: $color-black !important;
}

.btn-link:hover,
.btn-link:focus {
  color: $color-black !important;
  text-decoration: none;
  border-bottom: 2px solid $btn-link-underline;
}
