// siemens energy color palette
// primary colors
$color-se-dark-purple: #1b1534;
$color-se-medium-purple: #4d217a;
$color-se-purple: #641e8c;
$color-se-petrol: #099;
$color-white: #fff;
$color-black: #000;
// secondary or accent colors
$color-se-bright-purple: #8a00e5;
$color-se-brown-1: #cfc0be;
$color-se-brown-2: #af9e9c;
$color-se-brown-3: #8f7c7a;
$color-se-brown-4: #7d6766;
$color-se-gray-1: #d7e4ee;
$color-se-gray-2: #b8cedb;
$color-se-gray-3: #96b0c0;
$color-se-gray-4: #7b919d;
$color-se-green-1: #00fd79;
$color-se-green-2: #14da79;
$color-se-green-3: #27b66d;
$color-se-green-4: #009b55;
$color-se-blue-1: #6addff;
$color-se-blue-2: #21c0ff;
$color-se-blue-3: #0084e1;
$color-se-blue-4: #0057c6;
$color-se-yellow-1: #fff91f;
$color-se-yellow-2: #f6e600;
$color-se-yellow-3: #e2d000;
$color-se-yellow-4: #c6ae00;
// additional palette - colors for skin tones only
$color-se-skin-tone-1a: #784731;
$color-se-skin-tone-1b: #65361e;
$color-se-skin-tone-2a: #d16f4d;
$color-se-skin-tone-2b: #c45933;
$color-se-skin-tone-3a: #d89a6f;
$color-se-skin-tone-3b: #db8464;
$color-se-skin-tone-4a: #ffcdb1;
$color-se-skin-tone-4b: #ffad84;
// additional palette - hot and cold for light/dark backgrounds
$color-se-hot-ligth-bg: #da1e28;
$color-se-cold-ligth-bg: #0057c6;
$color-se-hot-dark-bg: #e85960;
$color-se-cold-dark-bg: #0084e1;

// generic colors
$color-grey: #ccc;
$color-grey-2: #dfe6ed;
$color-blue: #337ab7;

// side bar - colors
$color-sidebar-background: $color-white;
$color-sidebar-link-active: $color-white;
$color-sidebar-active-item: $color-white;
$color-sidebar-link-hover: $color-grey-2;
$color-sidebar-item-text: $color-blue;

// buttons - colors
$btn-shadowin-color: rgba(0, 0, 0, 0.16);
$btn-shadowout-color: rgba(0, 0, 0, 0.12);
$btn-hover-shadowin-color: rgba(0, 0, 0, 0.18);
$btn-hover-shadowout-color: rgba(0, 0, 0, 0.15);

$btn-primary: $color-se-bright-purple;
$btn-primary-focus: $color-se-purple;

$btn-secondary: $color-white;
$btn-secondary-focus: $color-grey-2;
$btn-secondary-border-text: $color-se-bright-purple;
$btn-secondary-border-text-focus: $color-se-purple;

$btn-default: $color-se-blue-1;
$btn-default-focus: $color-se-blue-2;
$btn-default-active: $color-se-blue-3;

$btn-success: $color-se-green-1;
$btn-success-focus: $color-se-green-2;
$btn-success-active: $color-se-green-4;

$btn-info: $color-se-blue-2;
$btn-info-focus: $color-se-blue-3;
$btn-info-active: $color-se-blue-4;

$btn-warning: $color-se-yellow-2;
$btn-warning-focus: $color-se-yellow-3;
$btn-warning-active: $color-se-yellow-4;

$btn-danger: $color-se-hot-ligth-bg;
$btn-danger-focus: $color-se-hot-dark-bg;
$btn-danger-active: $color-se-hot-dark-bg;

$btn-link-underline: $color-se-bright-purple;
